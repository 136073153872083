<template>
  <!-- Start 404 Error Content -->
  <section class="error-page text-center">
    <div class="container">
      <h1>404</h1>
      <h3>Error Page not found</h3>
      <p>
        The page you are looking for was moved, removed, renamed or might never
        existed.
      </p>
      <router-link class="main-btn" to="/index-1"><span>Back Home</span></router-link>
    </div>
  </section>
  <!--  End 404 Error Content -->
</template>

<script>
export default {
};
</script>

<style lang="scss" scoped></style>